import * as React from "react";
import {FunctionComponent} from "react";
import {SvgIconProps} from "@material-ui/core";


export const BscScanIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={121.378}
            height={121.333}
            viewBox="0 0 121.378 121.333"
            {...props}
        >
            <path
                data-name="Path 1"
                d="M25.222 57.766a5.144 5.144 0 015.168-5.143l8.568.028a5.151 5.151 0 015.151 5.151v32.4c.965-.286 2.2-.591 3.559-.911a4.292 4.292 0 003.309-4.177V44.927a5.152 5.152 0 015.151-5.152h8.594a5.152 5.152 0 015.151 5.152v37.3s2.15-.87 4.243-1.754a4.3 4.3 0 002.625-3.957V32.049a5.151 5.151 0 015.15-5.151h8.585a5.151 5.151 0 015.146 5.151v36.617c7.443-5.394 14.986-11.882 20.972-19.683a8.646 8.646 0 001.316-8.072A60.636 60.636 0 108.055 91.019a7.668 7.668 0 007.316 3.79c1.624-.143 3.646-.345 6.05-.627a4.29 4.29 0 003.805-4.258V57.766"
                fill="#12161c"
            />
            <path
                data-name="Path 2"
                d="M25.039 109.727a60.656 60.656 0 0096.339-49.061c0-1.4-.065-2.778-.158-4.152-22.163 33.055-63.085 48.508-96.181 53.213"
                fill="#f0b90b"
            />
        </svg>
    )
}